var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dropzone",class:{
    'w-full min-h-[250px] p-6 border border-dashed bg-gray-50 flex flex-col justify-center items-center rounded-lg': true,
    'border-red-500': _vm.isError
  },on:{"drop":function($event){$event.preventDefault();return _vm.onDropFile.apply(null, arguments)}}},[_c('span',{staticClass:"font-lato font-medium text-sm text-center leading-6 text-blue-gray-800 mb-3"},[_vm._v(" "+_vm._s(_vm.guide)+" ")]),_c('div',{staticClass:"flex flex-row gap-x-4"},[_c('div',{staticClass:"flex flex-col gap-3 items-center"},[_c('SearchFolderIcon',{class:{'opacity-50': _vm.disabled}}),_c('label',{staticClass:"cursor-pointer"},[_c('span',{class:{
            'text-sm text-blue-500': true,
            'opacity-50': _vm.disabled
          }},[_vm._v(" Pilih file ")]),_c('input',{attrs:{"type":"file","hidden":"","accept":_vm.accept,"disabled":_vm.disabled,"data-cy":_vm.dataCy ? `${_vm.dataCy}-dropzone__input-file` : 'dropzone__input-file'},on:{"change":_vm.onChooseFile}})])],1),(_vm.isLinkField)?_c('div',{staticClass:"flex flex-col gap-3 items-center"},[_c('LinkIcon',{class:{'opacity-50': _vm.disabled}}),_c('button',{class:{
          'text-green-500': true,
          'opacity-50': _vm.disabled
        },attrs:{"disabled":_vm.disabled,"data-cy":_vm.dataCy ? `${_vm.dataCy}-dropzone__button-link` : 'dropzone__button-link'},on:{"click":_vm.onClick}},[_c('span',{staticClass:"text-sm"},[_vm._v("Link Document")])])],1):_vm._e(),(_vm.isYoutubeField)?_c('div',{staticClass:"flex flex-col gap-3 items-center"},[_c('YoutubeIcon',{class:{'opacity-50': _vm.disabled}}),_c('button',{class:{
          'text-red-500': true,
          'opacity-50': _vm.disabled
        },attrs:{"disabled":_vm.disabled},on:{"click":_vm.onClick}},[_c('span',{staticClass:"text-sm"},[_vm._v("Link Youtube")])])],1):_vm._e()]),_vm._t("description")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }